<script lang="ts" setup>
import type { AsyncDataRequestStatus } from '#app'
import AboutSection from '~/components/sections/AboutSection.vue'
import ArticlesSection from '~/components/sections/ArticlesSection.vue'
import BannersSection from '~/components/sections/BannersSection.vue'
import BrandsSection from '~/components/sections/BrandsSection.vue'
import CollectionsSection from '~/components/sections/CollectionsSection.vue'
import ConsultationsSection from '~/components/sections/ConsultationsSection.vue'
import CountdownSection from '~/components/sections/CountdownSection.vue'
import NewBannersSection from '~/components/sections/NewBannersSection.vue'
import PartnerSection from '~/components/sections/PartnerSection.vue'
import ProductsSection from '~/components/sections/ProductsSection.vue'
import SpecialOffersSection from '~/components/sections/SpecialOffersSection.vue'
import SubscriptionSection from '~/components/sections/SubscriptionSection.vue'
import { useAction } from '~/composables/action'
import { useSharing } from '~/composables/sharing'
import { useWindowHelpers } from '~/composables/window-helpers'
import constants from '~/constants'
import { banners2 } from '~/constants/banners'
import type Action from '~/enums/action'
import Page from '~/enums/page'
import ProductSort from '~/enums/product-sort'
import JsonApiHelper from '~/helpers/json-api-helper'
import RetailRocketApiService from '~/services/retail-rocket-api-service'

const bannerImagePaths = ['/images/pages/tests/banners/1.jpg', '/images/pages/tests/banners/1_small.jpg']
const title = 'iTAB — платформа здорового образа жизни'

const { $apiHelper } = useNuxtApp()
const route = useRoute()
const { performActionIfNeeded } = useAction()
const { parsePromoCodeFromQuery } = useSharing()
const { scrollToSavedPosition } = useWindowHelpers()

const { MostPopular, Newest } = ProductSort
const params = { 'page[size]': 12 }
const paramsForConsultations = {
  ...params,
  'filter[categoryId]': constants.categories.consultations.id,
  sort: ProductSort.Random
}
const paramsForProducts = { ...params, sort: Newest }

const { data, status } = await useLazyAsyncData(
  () => Promise.all([
    // "Статьи и материалы".
    $apiHelper.articles.getArticles(params),
    // "Баннеры".
    $apiHelper.banners.getBanners({ 'filter[platform]': 'website' }),
    // "Наши бренды".
    $apiHelper.brands.getBrands({ 'filter[hasImages]': 'yes', include: 'images', 'page[size]': 24 }),
    // "Подборки от специалистов".
    $apiHelper.collections.getCollections(params),
    // "Нужна помощь?".
    $apiHelper.products.getProducts(paramsForConsultations),
    // "Скидки этой недели".
    $apiHelper.products.getProducts({
      ...paramsForProducts,
      'filter[categoryId]': constants.categories.thisWeekDiscounts.id
    }),
    // "Популярное".
    $apiHelper.products.getProducts({ ...paramsForProducts, sort: MostPopular }),
    // "Новинки".
    $apiHelper.products.getProducts(paramsForProducts),
    // "Акции".
    // $apiHelper.specialOffers.getSpecialOffers(params)
  ]),
  {
    transform: (
      [
        articlesResponse,
        bannersResponse,
        brandsResponse,
        collectionsResponse,
        consultationsResponse,
        productsResponse1,
        productsResponse2,
        productsResponse3
        // specialOffersResponse
      ]: any[]
    ) => {
      return {
        articlesData: JsonApiHelper.denormalizeResponse(articlesResponse).data,
        bannersData: JsonApiHelper.denormalizeResponse(bannersResponse).data,
        brandsData: JsonApiHelper.denormalizeResponse(brandsResponse).data,
        collectionsData: JsonApiHelper.denormalizeResponse(collectionsResponse).data,
        consultationsResponseData: JsonApiHelper.denormalizeResponse(consultationsResponse).data,
        productsData1: JsonApiHelper.denormalizeResponse(productsResponse1).data,
        productsData2: JsonApiHelper.denormalizeResponse(productsResponse2).data,
        productsData3: JsonApiHelper.denormalizeResponse(productsResponse3).data
        // specialOffersData: JsonApiHelper.denormalizeResponse(specialOffersResponse).data
      }
    }
  }
)

useHead({
  title,
  meta: [{
    name: 'description',
    content: 'iTAB — онлайн-магазин по продаже нутрицевтиков и натуральных экологичных товаров для здоровья, молодости и красоты. Качественные витамины, БАД, косметика. Широкий ассортимент, известные производители. Доставка по Москве, во все регионы России и по миру.'
  }]
})

const articlesData = computed<any>(() => data.value?.articlesData ?? [])
const bannersData = computed<any>(() => data.value?.bannersData ?? [])
const brandsData = computed<any>(() => data.value?.brandsData ?? [])
const collectionsData = computed<any>(() => data.value?.collectionsData ?? [])
const consultationsData = computed<any>(() => data.value?.consultationsResponseData ?? [])
const productsData1 = computed<any>(() => data.value?.productsData1 ?? [])
const productsData2 = computed<any>(() => data.value?.productsData2 ?? [])
const productsData3 = computed<any>(() => data.value?.productsData3 ?? [])
// const specialOffersData = computed<any>(() => data.value?.specialOffersData ?? [])

watch(status, (x: AsyncDataRequestStatus): void => {
  if (x === 'success') {
    scrollToSavedPosition()
  }
})

onMounted(async (): Promise<void> => {
  const query = { ...route.query }
  const { action } = query

  await performActionIfNeeded(action as Action, query) // For backward compatibility.

  await parsePromoCodeFromQuery()

  setTimeout(RetailRocketApiService.render, 0)
})
</script>

<template>
  <div>
    <section>
      <div class="container">
        <h1 class="visually-hidden">
          {{ title }}
        </h1>
      </div>
    </section>

    <CountdownSection class="-mt-4 mb-10 md:-mt-10" event="green-friday" is-active />

    <NewBannersSection :banners-data="bannersData" />

    <!--<BannersSection :banners="banners1" />-->

    <!--<SearchSection class="mt-10" title="Система умного поиска" />-->

    <!-- Ранее вы покупали -->
    <RecommendationBlock :ids="['62de6c57a46052a3d5db61ad', '63528557bb745c063b0b57e4']" />

    <!-- Специально для вас -->
    <RecommendationBlock :ids="['62de6c6b9b6a8f8e07f08d14', '63528566bb745c063b0b57eb']" />

    <section class="mt-10">
      <div class="container">
        <ThinBanner
          :banner-image-paths="bannerImagePaths"
          button-title="Подобрать витамины"
          class="mb-6"
          :path="Page.Tests"
          :title="title"
        />
      </div>
    </section>

    <Preloader v-if="status === 'pending'" />
    <template v-else-if="status === 'success'">
      <ConsultationsSection class="mt-10" :products-data="consultationsData" />

      <PartnerSection class="mt-10" />

      <CollectionsSection
        class="mt-10"
        :collections-data="collectionsData"
        :title="'Подборки от\xA0специалистов'"
      />
    </template>

    <BannersSection :banners="banners2" class="mt-10" />

    <Preloader v-if="status === 'pending'" />
    <template v-else-if="status === 'success'">
      <ProductsSection
        class="mt-10"
        :path="constants.categories.thisWeekDiscounts.path"
        :products-data="productsData1"
        :title="constants.categories.thisWeekDiscounts.name"
      />

      <ProductsSection
        class="mt-10"
        :path="Page.Products"
        :products-data="productsData2"
        :query-parameters="{ sort: ProductSort.MostPopular }"
        title="Популярное"
      />

      <ProductsSection
        class="mt-10"
        :path="Page.Products"
        :products-data="productsData3"
        :query-parameters="{ sort: ProductSort.Newest }"
        title="Новинки"
      />

      <SpecialOffersSection class="mt-10" />
    </template>

    <AboutSection class="mt-10" />

    <Preloader v-if="status === 'pending'" />
    <template v-else-if="status === 'success'">
      <BrandsSection :brands-data="brandsData" class="mt-10" title="Наши бренды" />

      <ArticlesSection :articles-data="articlesData" class="mt-10" :title="'Статьи и\xA0материалы'" />
    </template>

    <SubscriptionSection class="mt-10" />
  </div>
</template>
