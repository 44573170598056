<script lang="ts" setup>
import Page from '~/enums/page'
import QueryAction from '~/enums/query-action'

const router = useRouter()
const title = 'Станьте специалистом iTAB'
</script>

<template>
  <section>
    <div class="container">
      <div class="relative overflow-hidden rounded-2xl bg-main-blue px-3 py-4 font-medium md:rounded-3xl md:p-10">
        <div class="mb-2 size-full w-[235px] text-2xl leading-6 text-white md:mb-8 md:w-auto md:text-[40px] md:leading-[44px]">
          Станьте специалистом iTAB
        </div>
        <div class="flex flex-col md:flex-row">
          <div
            class="
            mb-5 w-[235px] text-sm tracking-tight
            text-white md:mb-0 md:w-[424px] md:text-[23px] md:leading-6 md:tracking-normal
          "
          >
            Получайте бонусы на&nbsp;личный счёт и&nbsp;скидки на&nbsp;продукты для клиентов
          </div>
          <div class="flex space-x-[10px]">
            <div
              class="cursor-pointer rounded-2xl bg-white p-2 text-sm text-main-blue md:px-6 md:py-3 md:text-base"
              @click="router.push(Page.Partner)"
            >
              Подробнее
            </div>
            <div
              class="cursor-pointer rounded-2xl border border-solid border-white p-2 text-sm text-white md:px-6 md:py-3 md:text-base"
              @click="router.push(Page.Partner + '?action=' + QueryAction.OpenCreatePromoCodeApplicationModalWindow)"
            >
              Стать специалистом
            </div>
          </div>
        </div>
        <img
          v-lazy="'/images/sections/specialist_2.png'"
          :alt="title"
          class="absolute right-0 top-0 z-0 h-full object-cover md:-top-16 md:right-16 md:h-[220%]"
          loading="lazy"
        >
      </div>
    </div>
  </section>
</template>
