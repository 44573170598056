<script lang="ts" setup>
import {
  addYears,
  differenceInDays,
  differenceInMilliseconds,
  intervalToDuration,
  parseISO,
  startOfYear
} from 'date-fns'
import { useModal } from 'vue-final-modal'

import AboutGreenFriday from '~/components/AboutGreenFriday.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import constants from '~/constants'
import SubscriptionForm from '~/enums/subscription-form'
import helpers from '~/helpers'

type Countdown = { days: number | null, hours: number | null, minutes: number | null, seconds: number | null }

const countdownIntervalInMs = 1000

const blackFridayDateTime = '2023-10-13T00:00:00+03:00'
const greenFridayDateTime = '2024-10-18T00:00:00+03:00'

const { day, hour, minute, second } = constants.wordForms
const wordFormMapping = new Map([['days', day], ['hours', hour], ['minutes', minute], ['seconds', second]])

const props = withDefaults(
  defineProps<{
    event: 'black-friday' | 'green-friday' | 'new-year'
    isActive?: boolean
  }>(),
  { isActive: false }
)

const countdown = ref<Countdown>({ days: null, hours: null, minutes: null, seconds: null })
const eventDateTime = ref<Date | null>(null)
const interval = ref<ReturnType<typeof setInterval> | null>(null)
const isActiveLocal = ref(false)

const countdownItemClassList = computed(() => {
  switch (props.event) {
    case 'black-friday':
      return 'bg-black text-white'
    case 'green-friday':
      return 'bg-[#eef7eb] text-main-green' // #eef7eb is 10% mix of text-main-green and white.
    case 'new-year':
      return 'bg-[#ffe6ed] text-main-red' // #ffe6ed is 10% mix of text-main-red and white.
    default:
      return null
  }
})

const countdownTitleClassList = computed(() => {
  switch (props.event) {
    case 'black-friday':
      return 'text-black'
    case 'green-friday':
      return 'text-main-green cursor-pointer'
    case 'new-year':
      return 'text-main-red'
    default:
      return null
  }
})

const title = computed(() => {
  switch (props.event) {
    case 'black-friday':
      return 'До\xA0«Чёрной пятницы»'
    case 'green-friday':
      return 'До\xA0«Зелёной пятницы»'
    case 'new-year':
      return 'До\xA0Нового года'
    default:
      return null
  }
})

onMounted(() => {
  isActiveLocal.value = props.isActive

  if (!props.isActive) {
    return
  }

  eventDateTime.value = getEventDateTime()

  if (!eventDateTime.value) {
    return
  }

  updateCountdown()

  interval.value = setInterval(updateCountdown, countdownIntervalInMs)
})

function getEventDateTime (): Date | null {
  switch (props.event) {
    case 'black-friday':
      return parseISO(blackFridayDateTime)
    case 'green-friday':
      return parseISO(greenFridayDateTime)
    case 'new-year':
      return startOfYear(addYears(new Date(), 1))
    default:
      return null
  }
}

function updateCountdown (): void {
  const start = new Date()
  const end = eventDateTime.value!

  if (differenceInMilliseconds(end, start) < 0 || differenceInDays(end, start) > 14) {
    if (interval.value) {
      clearInterval(interval.value)
    }

    isActiveLocal.value = false

    return
  }

  const { days, hours, minutes, seconds } = intervalToDuration({ start, end })
  countdown.value = { days: days ?? 0, hours: hours ?? 0, minutes: minutes ?? 0, seconds: seconds ?? 0 }
}

async function openAboutGreenFridayModalWindow (): Promise<void> {
  await useModal({ component: AboutGreenFriday }).open()
}

async function openCreateSubscriberModalWindow (): Promise<void> {
  let subscriptionForm = null

  if (props.event === 'black-friday') {
    subscriptionForm = SubscriptionForm.BlackFriday
  } else if (props.event === 'green-friday') {
    subscriptionForm = SubscriptionForm.GreenFriday
  }

  console.log(props.event, subscriptionForm)

  if (subscriptionForm === null) {
    return
  }

  await useModal({ component: CreateSubscriber, attrs: { subscriptionForm } }).open()
}
</script>

<template>
  <section v-if="isActiveLocal" class="bg-white py-4 md:py-10">
    <div class="container">
      <div class="-mx-0.5 -my-1 flex flex-wrap items-center justify-center min-[480px]:-mx-2 min-[480px]:-my-1 md:-mx-3">
        <div
          class="
            mx-0.5 my-1 flex items-center justify-center space-x-1 font-medium
            min-[480px]:mx-2 min-[480px]:my-1
            md:mx-3 md:space-x-2 md:text-[32px]
          "
          :class="countdownTitleClassList"
          @click="event === 'green-friday' ? openAboutGreenFridayModalWindow() : () => {}"
        >
          <span>{{ title }}</span>
          <SvgIcon v-if="event === 'green-friday'" name="outlined/help-outline" />
        </div>

        <div class="mx-0.5 my-1 flex justify-center min-[480px]:mx-2 min-[480px]:my-1 md:mx-3">
          <div class="flex flex-wrap items-center space-x-1 min-[480px]:space-x-2 md:space-x-4">
            <div
              v-for="(v, k) in countdown"
              :key="k"
              class="
                flex size-12 flex-col items-center justify-center rounded-xl text-[28px] leading-7
                md:size-16 md:text-4xl md:leading-9
              "
              :class="countdownItemClassList"
            >
              <template v-if="v !== null">
                {{ v }}
                <div class="text-[11px] leading-[11px] md:text-sm md:leading-[14px]">
                  {{ helpers.getDeclension(v, wordFormMapping.get(k)!) }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <BasicButton
          v-if="event === 'green-friday' || event === 'black-friday'"
          class="mx-0.5 my-1 min-[480px]:mx-2 min-[480px]:my-1 md:mx-3"
          color="green"
          is-small
          :title="'Напомнить о\xA0старте'"
          @click="openCreateSubscriberModalWindow"
        />
      </div>
    </div>
  </section>
</template>
