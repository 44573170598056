<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import CreateSubscriber from '~/components/modal-windows/CreateSubscriber.vue'
import SubscriptionForm from '~/enums/subscription-form'
import type { BannerData } from '~/types/banners'

const props = defineProps<{
  bannersData: BannerData[]
}>()

const bannerData = computed<BannerData | null>(() => props.bannersData[0] ?? null)

async function openCreateSubscriberModalWindow (): Promise<void> {
  await useModal({ component: CreateSubscriber, attrs: { subscriptionForm: SubscriptionForm.GreenFriday } }).open()
}
</script>

<template>
  <section>
    <div class="container">
      <div class="flex">
        <div
          class="
            relative w-full cursor-pointer overflow-hidden rounded-2xl bg-[#e7e7e7] pb-[56.25%]
            md:h-[364px] md:w-[1016px] md:rounded-3xl md:pb-0
          "
          @click="bannerData?.id === '1' ? openCreateSubscriberModalWindow() : () => {}"
        >
          <BannerPicture
            v-if="bannerData"
            :banner-name="bannerData.attributes.name"
            class="absolute hidden size-full md:inline-block"
            :main="bannerData.meta.images.desktop.main"
            :sources="bannerData.meta.images.desktop.sources"
          />
          <BannerPicture
            v-if="bannerData"
            :banner-name="bannerData.attributes.name"
            class="absolute inline-block size-full md:hidden"
            :main="bannerData.meta.images.mobile.main"
            :sources="bannerData.meta.images.mobile.sources"
          />
        </div>
        <BannerCards class="ml-4 hidden w-[328px] md:block" />
      </div>
    </div>
  </section>
</template>
