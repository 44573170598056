<script lang="ts" setup>
import CardWithImage from '~/components/cards/CardWithImage.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import helpers from '~/helpers'

const path = Page.Collections

const props = defineProps<{
  collectionsData: any[]
  title: string
}>()
</script>

<template>
  <section v-if="collectionsData.length" class="bg-white">
    <div class="container">
      <Heading element="h2" :path="path" :title="title" title-class="h1">
        <template #buttons>
          <BasicButton
            :aria-label="title"
            color="blue"
            icon="outlined/east"
            is-icon-only
            is-small
            @click="$router.push(path)"
          />
        </template>
      </Heading>
    </div>

    <Gallery :options="constants.galleryOptions">
      <CardWithImage
        v-for="x in collectionsData"
        :key="x.id"
        class="max-3-slides swiper-slide"
        :data="x"
        has-border
        :path="helpers.getCollectionPath(x)"
      />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/css/gallery';

section {
  box-sizing: border-box;
  padding: 40px 0;

  @media screen and (max-width: 767px) {
    padding: 32px 0;
  }
}
</style>
